@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Coiny&family=Concert+One&family=Protest+Strike&display=swap");


@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue"), url(./text/helveticaneue.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue"), url(./text/helveticaneue.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Ranille";
  src: url(./text/ranille/RanilleNormalRegular-EaZJj.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi-Variable';
  src: url('./text/satoshi/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-VariableItalic';
  src: url('./text/satoshi/Satoshi-VariableItalic.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Light';
  src: url('./text/satoshi/Satoshi-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-LightItalic';
  src: url('./text/satoshi/Satoshi-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Regular';
  src: url('./text/satoshi/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-Italic';
  src: url('./text/satoshi/Satoshi-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Medium';
  src: url('./text/satoshi/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-MediumItalic';
  src: url('./text/satoshi/Satoshi-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Bold';
  src: url('./text/satoshi/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-BoldItalic';
  src: url('./text/satoshi/Satoshi-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi-Black';
  src: url('./text/satoshi/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi-BlackItalic';
  src: url('./text/satoshi/Satoshi-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}


/* Text Transform */
.clickable {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.noUppercase {
  text-transform: none !important;
}

.noDecoration {
  text-decoration: none !important;
}
/* Text Transform Emds*/

/* Display text */
.display-table {
  display: table;
}

.display-cell {
  display: table-cell;
}

.display-vertical-align {
  display: table-cell;
  vertical-align: middle;
}

.display-inline {
  display: inline-block !important;
}

.display-flex {
  display: flex;
  gap: 10px;
}

.display-column {
  flex-direction: column;
  align-items: center; 
}

/* End Display text */

/* Align Content */
.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right;
}

.flex-box {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: flex-start; /* Align items horizontally to the start */
}

.justify-content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-content-end {
  display: flex;
  justify-content: right;
  align-items: right;
}

.flex-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}
/* Align Content Ends */

/* Positioning */

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.r-0 {
  right: 0;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.italic {
  font-style: italic !important;
}

.blur {
  filter: blur(2.5px);
}

/* Positioning */

/* Margin */

.m0 {
  margin: 0 0 0 0 !important;
}

.mt-n-10 {
  margin-top: -10px;
}

.mt-n-100 {
  margin-top: -100px;
}

.mt-n-150 {
  margin-top: -150px;
}

.mt-n-300 {
  margin-top: -300px;
}


.mt-n-50 {
  margin-top: -50px;
}

.m-0 {
  margin: 0;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-5-real {
  margin-top: 5px !important;
}

.mt-7 {
  margin-top: 7px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-80 {
  margin-top: 80px !important;
}


.mt-100 {
  margin-top: 100px !important;
}

.mt-125 {
  margin-top: 125px;
}


.mt-150 {
  margin-top: 150px;
}


.mb-05 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mr-5 {
  margin-right: 5px;
}

.mr-7 {
  margin-right: 7px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.ml-n-5 {
  margin-left: -5px;
}

.ml-n-10 {
  margin-left: -10px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-40 {
  margin-left: 40px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-100 {
  margin-left: 100px;
}


.mb-n-50 {
  margin-bottom: -50px;
  z-index: 1;
}

/* Margin Ends */

.pr-0 {
  padding-right: 0 !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-5 {
  padding-left: 5 !important;
}

.pt-0 {
  padding-top: 0;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-200 {
  padding-top: 200 !important;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}


/* Modal */

.modal-choose {
  width: 100% !important;
  max-width: 600px !important;
  max-height: none !important;
}


.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%235e2921' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}


.Mui-expanded {
  margin: 0 !important;
}

.css-1lj39kh-MuiAccordionDetails-root {
  padding: 0 0 0 15px !important;
}

.css-1808mag-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.css-1808mag-MuiPaper-root-MuiAccordion-root {
  background-color: transparent !important;
}

.css-1pug5wj {
  box-shadow: none !important;
  background-color: transparent !important;
}

.css-l9xe8y {
   padding: 0 0 0 15px !important;
}

.navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}